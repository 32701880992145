import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from '../../modules/networkTools';

export const getRecruiterProfile: any = createAsyncThunk(
  'recruiter/profile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('recruiter/profile');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

interface RecruiterProfileDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  dob: string;
}

export const putRecruiterProfile: any = createAsyncThunk(
  'recruiter/profileDelete',
  async (
    {
      id,
      firstName,
      lastName,
      email,
      phone,
      street,
      addressLine2,
      city,
      state,
      zip,
      dob
    }: RecruiterProfileDTO,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`recruiter/profile`, {
        id,
        firstName,
        lastName,
        email,
        phone,
        street,
        addressLine2: addressLine2 === '' ? undefined : addressLine2,
        city,
        state,
        zip,
        dob
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUnSeenAwardsCount: any = createAsyncThunk(
  'recruiter/unseenAwards',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('recruiter/profile/awards/unseen/count');

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUnSeenAwardsCounterReset: any = createAsyncThunk(
  'recruiter/resetSeenAwards',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('recruiter/profile/awards/seen');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

interface RecruiterProfileAwardsDTO {
  page: number;
  size: string;
  sort: { title: string; direction: string }[];
}

export const getRecruiterProfileAwards: any = createAsyncThunk(
  'recruiter/profile/awards',
  async ({ page, size, sort }: RecruiterProfileAwardsDTO, { rejectWithValue }) => {
    const defaultSort = '&sort=dateTime,desc';
    let sortArray: string | string[] = '';
    if (sort && sort.length > 0) {
      sortArray = sort
        .map((item) => item.direction && `&sort=${item.title},${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `recruiter/profile/awards?page=${page}&size=${size}${sortArray || defaultSort}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
