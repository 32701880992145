//Regex
export const EMAIL_REGEX = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const US_PHONE_REGEX = /^\(\d{3}\) \d{3}-\d{4}/;
export const EMAIL_AND_PHONE_COMBINED_REGEX =
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|^\(\d{3}\) \d{3}-\d{4}/; //does not allow 	Б	В	Г
export const LATIN_CHARS_REGEX = /^[A-Za-z\s]*$/;
export const TEXT_AREA_REGEX = /^[A-Za-z\s0-9.-]*$/;
export const LATIN_CHARS_AND_NUMBERS_REGEX = /^[A-Za-z\s0-9]*$/;
export const ASCII_CHARS_REGEX = /^[\x00-\x7F]*$/;
export const NUMBERS_REGEX = /^[0-9]*$/;
export const EMPTY_SPACES_REGEX = /^(?!\s+$)/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

//Urls
export const LOGIN_PAGE_URL = '/auth/login';
export const RESET_PASSWORD_PAGE_URL = '/auth/resetPassword';
export const SIGN_UP_PAGE_URL = '/auth/signUp';
export const CANDIDATE_CREATE_PAGE_URL = '/community-health-worker';
export const THANK_YOU_PAGE_URL = '/thankYou';

//Placeholders
export const PLACEHOLDER_PHONE = '(000) 000-0000';
export const PLACEHOLDER_EMAIL = 'example@mail.com';
export const PLACEHOLDER_FIRST_NAME = 'John';
export const PLACEHOLDER_LAST_NAME = 'Doe';

//Masks
export const PHONE_INPUT_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export { default as endpoints } from './endpoints';
