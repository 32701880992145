import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { operations } from '../../api/generated-types';
import { axiosPrivate, axiosPublic } from '../../modules/networkTools';
import { RecruiterItem } from './candidateSlice';

type RegisterCandidate = operations['registerForCoach'];

interface CandidateRegDTO {
  candidate: RegisterCandidate['requestBody']['content']['application/json'];
  referenceLink: string | null | undefined;
  candidateId: string | null;
}

interface CandidateStatusDTO {
  candidateId: number;
  candidateStatus: string;
}

interface CandidatePaidDTO {
  candidateId: number;
  paymentType: string;
}

interface CoachDTO {
  page: number;
  size: string;
  sort: { title: string; direction: string }[];
  ids?: number;
  statuses?: string[] | string;
  q?: string;
}

interface RecruiterDTO {
  recruiterId: string;
  candidateId: number;
}

export const postCandidateRegistration = createAsyncThunk<
  RegisterCandidate['responses']['200'],
  CandidateRegDTO
>(
  'candidate/candidateRegistration',
  async ({ candidate, referenceLink, candidateId }: CandidateRegDTO, { rejectWithValue }) => {
    const url = referenceLink
      ? `candidate/${candidateId}/registration/recruiter/${referenceLink}`
      : `candidate/${candidateId}/registration`;
    const axiosRole = referenceLink ? axiosPublic : axiosPrivate;
    try {
      const response = await axiosRole.post(url, candidate);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidate: any = createAsyncThunk(
  'candidate/candidateInfo',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`candidate/${id}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putCandidateStatus: any = createAsyncThunk(
  'candidate/changeStatus',
  async ({ candidateId, candidateStatus }: CandidateStatusDTO, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('candidate/status', {
        candidateId,
        candidateStatus
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putCandidatePaid: any = createAsyncThunk(
  'candidate/changePaid',
  async ({ candidateId, paymentType }: CandidatePaidDTO, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('candidate/paid', {
        candidateId,
        paymentType
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateCV: any = createAsyncThunk(
  'candidate/downloadCV',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`candidate/${id}/download/cv`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateVideo: any = createAsyncThunk(
  'candidate/downloadVideo',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`candidate/${id}/download/video`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getExportPaymentsCSV: any = createAsyncThunk(
  'candidate/getExportPaymentsCSV',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`recruiter/payment/exportCsv`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateStatuses: any = createAsyncThunk(
  'candidate/statuses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('candidate/statuses');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateCampaigns: any = createAsyncThunk(
  'candidate/campaigns',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('candidate/campaigns');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateAmountOwed: any = createAsyncThunk(
  'candidate/amountOwed',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('candidate/amountOwed');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateList: any = createAsyncThunk(
  'candidate/list',
  async ({ page, size, sort, statuses, q }: CoachDTO, { rejectWithValue }) => {
    const defaultSort = '&sorts=appliedAt,desc';
    const QString = q ? `&q=${q}` : '';
    const statusesFiltered = statuses ? `&${statuses}` : '';
    let sortArray: string | string[] = '';
    if (sort && sort.length > 0) {
      sortArray = sort
        .map((item) => item.direction && `&sorts=${item.title},${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `candidate?page=${page}&size=${size}${
          sortArray || defaultSort
        }${QString}${statusesFiltered}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postNoteInput: any = createAsyncThunk(
  'candidate/postNoteInput',
  async ({ candidateId, id, note }: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`candidate/${candidateId}/note`, {
        id,
        note
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putRecruiterData: any = createAsyncThunk(
  'candidate/putRecruiterData',
  async (data: RecruiterItem, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`recruiter/profile/proxy-id`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getRecruiterList: any = createAsyncThunk(
  'recruiter/list',
  async ({ page, size, sort, q }: CoachDTO, { rejectWithValue }) => {
    const defaultSort = '';
    const QString = q ? `&q=${q}` : '';
    let sortArray: string | string[] = '';
    if (sort && sort.length > 0) {
      sortArray = sort
        .map((item) => item.direction && `&sorts=${item.title},${item.direction}`)
        .join('');
    }

    try {
      const response = await axiosPrivate.get(
        `recruiter?page=${page}&size=${size}${sortArray || defaultSort}${QString}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putRecruiterName: any = createAsyncThunk(
  'recruiter/putRecruiterName',
  async ({ recruiterId, candidateId }: RecruiterDTO, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('coach/assign/recruiter/to/candidate', {
        recruiterId,
        candidateId
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
