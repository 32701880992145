import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Header } from '../../modules';
import style from './layout.module.scss';

interface LayoutProps {}

const Layout: React.FunctionComponent<LayoutProps> = () => {
  return (
    <>
      <Header />
      <div className={style.layoutContainer}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
