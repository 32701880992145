import React, { FC } from 'react';
import { ResponsiveSidebarPopperChecker, Title, Button } from '../../../components';
import style from './styles.module.scss';

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  open: boolean;
}

const InfoPopper: FC<Props> = ({ anchorEl, onClose, open }) => {
  const email = 'recruiter@impactcarehq.com';

  return (
    <ResponsiveSidebarPopperChecker
      open={open}
      anchorEl={anchorEl}
      title="How it Works"
      onClose={onClose}
    >
      <div className={style.info}>
        <Title type="h3" text="Recruit CHWs. Get paid. Here’s how:" />
        <ul>
          <li>
            <b>Do you know caring people in your community?</b> Tell them to apply to become an
            IMPaCT CHW using your referral link!
          </li>
          <li>
            <b>Earn fast.</b> When your candidates apply, you earn rewards.
          </li>
          <li>
            <b>You can earn up to $10,000!</b> The better the candidates, the more money you make.
            For every complete application, you get paid $10. If your candidate passes the screening
            you get $100. If they get hired, you make $1,000! Not bad!
          </li>
        </ul>
        <Title type="h3" text="To get started:" />
        <ul>
          <li>
            <b>Refer and earn. </b>
            <br />
            Share your referral link with people you think would be a good fit. As soon as one of
            your candidates submits a complete application we’ll contact you to get you paid.
          </li>
          <li>
            <b>We’ll ask you for a W-9 form.</b> When we contact you, we’ll ask you to email your{' '}
            <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">
              W-9
            </a>{' '}
            to us at <a href={`mailto: ${email}`}>email</a>.
          </li>
          <li>
            <b>Get your Dash card.</b> Once we have your W9 you will get a Dash card in the mail.
            Your payments are added to it and you can use it like a debit card!
          </li>
        </ul>
        <Button text={'Got it'} handleOnClick={onClose} />
      </div>
    </ResponsiveSidebarPopperChecker>
  );
};

export default InfoPopper;
