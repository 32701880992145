import React, { FC, useState } from 'react';
import { ResponsiveSidebarPopperChecker } from '../../../components';
import style from './styles.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  open: boolean;
}

const HelpPopper: FC<Props> = ({ anchorEl, onClose, open }) => {
  const [copied, setCopied] = useState(false);

  const onClosePopper = () => {
    setCopied(false);
    onClose();
  };

  const email = 'recruiter@impactcarehq.com';

  return (
    <ResponsiveSidebarPopperChecker
      anchorEl={anchorEl}
      title="Support"
      closeIcon={true}
      open={open}
      onClose={onClosePopper}
    >
      <div className={style.help}>
        <div className={style.wrapper}>
          <a href={`mailto: ${email}`}>{email}</a>
          <CopyToClipboard text={email || ''} onCopy={() => setCopied(true)}>
            {!copied ? (
              <button>Copy email</button>
            ) : (
              <button className={style.linkButtonCopied}>
                Copied <CheckIcon />
              </button>
            )}
          </CopyToClipboard>
        </div>
      </div>
    </ResponsiveSidebarPopperChecker>
  );
};

export default HelpPopper;
