import { createSlice } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import { notifyUserError, notifyUserSuccess } from '../../utils/notifications';
import {
  getCandidate,
  getCandidateAmountOwed,
  getCandidateCV,
  getCandidateCampaigns,
  getCandidateList,
  getCandidateStatuses,
  getCandidateVideo,
  getExportPaymentsCSV,
  postCandidateRegistration,
  postNoteInput,
  putCandidatePaid,
  putCandidateStatus,
  getRecruiterList,
  putRecruiterName
} from './candidateActions';

export interface ReferenceDTO {
  name: string;
  company: string;
  relationship: string;
  phoneOrEmail: string;
}

export interface FilterDTO {
  id: number;
  name: string;
}

export interface CandidateProfileDTO {
  name: string;
  phone: string;
  email: string;
  id: number | null;
  languageIds: string[];
  languages: string[];
  campaignId: string;
  notes: any[];
  howDidYouHearAboutUs?: string;
  campaign?: any;
  status?: string;
  educationList?: string[];
  workExperienceList?: string[];
  background?: string;
  references: ReferenceDTO[];
  areComfortableVisitingPeople?: boolean;
  videoExtension?: string;
  cvExtension?: string;
  cvFileName?: string;
  videoFileName?: string;
}

export interface RecruiterPaymentsItem {
  type: string;
  hasBeenPaid: boolean;
  amount: string;
  date: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface RecruiterItem {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  street: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  proxyId: string;
  w9TaxFormSubmitted: boolean;
}

export interface ContentItem {
  amountOwed: number;
  id: number;
  name: string;
  email: string;
  phone: string;
  score: string;
  status: string;
  appliedAt: string;
  statusDate: string;
  campaign: {
    id: number;
    name: string;
  };
  recruiter: RecruiterItem;
  recruiterPayments: RecruiterPaymentsItem[];
}

export interface CandidateSliceProps {
  candidateProfile: CandidateProfileDTO | null;
  candidateProfileView: CandidateProfileDTO | null;
  candidateId: string | null;
  candidateFormSectionApplyValid: boolean;
  candidateFormReferencesValid: boolean;
  candidateStatuses: FilterDTO[];
  candidateCampaigns: FilterDTO[];
  amountOwed: FilterDTO[];
  recruiterList: RecruiterItem[];
  candidateList: {
    content: ContentItem[] | [];
    page: number;
    size: number;
    totalElements: number;
  };
  candidateListLoading: boolean;
}
const initialReference = {
  name: '',
  company: '',
  relationship: '',
  phoneOrEmail: ''
};

const freshProfile = {
  name: '',
  phone: '',
  email: '',
  id: null,
  languageIds: [],
  languages: [],
  campaign: '',
  campaignId: '',
  notes: [],
  background: '',
  references: [initialReference, initialReference]
};
const initialState: CandidateSliceProps = {
  candidateProfile: freshProfile,
  candidateProfileView: null,
  candidateFormSectionApplyValid: false,
  candidateFormReferencesValid: false,
  candidateId: null,
  candidateStatuses: [],
  candidateCampaigns: [],
  recruiterList: [],
  amountOwed: [],
  candidateList: {
    content: [],
    page: 0,
    size: 5,
    totalElements: 0
  },
  candidateListLoading: false
};

export const candidateSlice = createSlice({
  name: 'candidateSlice',
  initialState,
  reducers: {
    updateCandidateData: (state, action) => {
      state.candidateProfile = { ...state.candidateProfile, ...action.payload };
    },
    createCandidateID: (state) => {
      const candidateId = uuid();
      state.candidateId = candidateId;
    },

    resetCandidateView: (state, action) => {
      state.candidateProfileView = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCandidateRegistration.fulfilled, (state: any, { payload }: any) => {
        state.candidateProfile = payload;
      })
      .addCase(postCandidateRegistration.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postNoteInput.fulfilled, (state: any, { payload }: any) => {
        // state.candidateProfile = payload;
        notifyUserSuccess('Note added successfully');
      })
      .addCase(postNoteInput.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getExportPaymentsCSV.fulfilled, (state: any, { payload }: any) => {
        state.candidateProfile = payload;
      })
      .addCase(getExportPaymentsCSV.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidate.fulfilled, (state: any, { payload }: any) => {
        state.candidateProfileView = payload;
      })
      .addCase(getCandidate.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putCandidateStatus.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putCandidatePaid.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateVideo.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateCV.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateStatuses.fulfilled, (state: any, { payload }: any) => {
        state.candidateStatuses = payload;
      })
      .addCase(getCandidateStatuses.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateCampaigns.fulfilled, (state: any, { payload }: any) => {
        state.candidateCampaigns = payload;
      })
      .addCase(getCandidateCampaigns.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateAmountOwed.fulfilled, (state: any, { payload }: any) => {
        state.amountOwed = payload;
      })
      .addCase(getCandidateAmountOwed.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateList.pending, (state: any) => {
        state.candidateListLoading = true;
      })
      .addCase(getCandidateList.fulfilled, (state: any, { payload }: any) => {
        state.candidateList = payload;
        state.candidateListLoading = false;
        notifyUserError(payload);
      })
      .addCase(getCandidateList.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getRecruiterList.fulfilled, (state: any, { payload }: any) => {
        state.recruiterList = payload.content;
        notifyUserError(payload);
      })
      .addCase(getRecruiterList.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putRecruiterName.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export const { updateCandidateData, createCandidateID, resetCandidateView } =
  candidateSlice.actions;
export default candidateSlice.reducer;
