import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from '../../components';
import { LOGIN_PAGE_URL } from '../../constants';
import { useUserAuthStatusChecker } from '../jwtDecoder';
import { ROLE } from './userRolesTypes';

interface PrivateRoutesProps {
  allowedRoles: ROLE[];
}

const PrivateRoutes: React.FunctionComponent<PrivateRoutesProps> = ({ allowedRoles }) => {
  const auth: any = useUserAuthStatusChecker();

  const location = useLocation();

  // IF USER  HAS TOKEN (isAuthenticated) AND HAS ROLE (isAuthorized) TO VISIT FORWARD TO THE ROUTE
  return auth?.token && auth?.roles.find((role: any) => allowedRoles?.includes(role)) ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : // IF USER !isAuthorized BUT isAuthenticated HIM TO  UNAUTHORIZED
  auth?.token ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    // IF USER !isAuthorized AND !isAuthenticated HIM TO LOGIN
    <Navigate to={LOGIN_PAGE_URL} state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
