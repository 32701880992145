// import { axiosPublic } from '../../modules/networkTools';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { operations } from '../../api/generated-types';
import { deleteLsBothTokens } from '../../modules/networkTools/localStorageTokens';

export const axiosThunk = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

type Register = operations['register'];
type Login = operations['login'];
type Logout = operations['logout'];
type RequestChangePasswordCode = operations['requestChangePasswordCode'];
type ChangePassword = operations['changePassword'];

type AdditionalUserProps = {
  street: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  dob: string;
};

export const postRegisterUser = createAsyncThunk<
  unknown,
  Register['requestBody']['content']['application/json'] & AdditionalUserProps,
  { rejectValue: Register['responses']['500']['content']['application/json']['errors'] }
>(
  'user/register',
  async (
    { firstName, lastName, email, phone, password, dob, street, addressLine2, city, state, zip },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosThunk.post(`recruiter/registration`, {
        firstName,
        lastName,
        email,
        phone,
        password,
        dob,
        street,
        addressLine2: addressLine2 === '' ? undefined : addressLine2,
        city,
        state,
        zip
      });
      return response.data as Register['responses'][200];
    } catch (error: any | AxiosError) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data.errors);
      }
    }
  }
);

export const postLoginUser = createAsyncThunk<
  Login['responses'][200]['content']['application/json'],
  Login['requestBody']['content']['application/json'],
  { rejectValue: Login['responses']['500']['content']['application/json']['errors'] }
>('user/login', async ({ username, password }, { rejectWithValue }) => {
  try {
    const response: any = await axiosThunk.post(`login`, { username, password });

    return response.data;
  } catch (error) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const putLogoutUser = createAsyncThunk<
  Logout['responses'][200],
  Logout['requestBody']['content']['application/json']['refreshToken'],
  { rejectValue: Logout['responses']['500']['content']['application/json']['errors'] }
>('user/logout', async (refreshToken: string, { rejectWithValue }) => {
  try {
    await axiosThunk.put(`logout`, { refreshToken });
    deleteLsBothTokens();
    window.location.reload();
  } catch (error) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const getResetPasswordCode = createAsyncThunk<
  'email' | 'phone' | undefined,
  RequestChangePasswordCode['parameters']['query'],
  {
    rejectValue: RequestChangePasswordCode['responses']['500']['content']['application/json']['errors'];
  }
>('user/resetGetCode', async ({ email, phone }, { rejectWithValue }) => {
  try {
    let methodOfReset: 'email' | 'phone' | undefined;
    const params: RequestChangePasswordCode['parameters']['query'] = {};

    if (email) {
      params.email = email;
      methodOfReset = 'email';
    }
    if (phone) {
      params.phone = phone;
      methodOfReset = 'phone';
    }
    await axiosThunk.get(`change-password/request/code`, {
      params
    });

    return methodOfReset as any;
  } catch (error: any | AxiosError) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const postChangePasswordUser = createAsyncThunk<
  ChangePassword['responses']['200'],
  ChangePassword['requestBody']['content']['application/json'],
  { rejectValue: ChangePassword['responses']['500']['content']['application/json']['errors'] }
>('user/changePassword', async ({ password, code, email, phone }, { rejectWithValue }) => {
  try {
    const response = await axiosThunk.post(`change-password`, {
      password,
      code,
      email,
      phone
    });

    return response.data;
  } catch (error: any | AxiosError) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

type City = {
  id: number;
  name: string;
};

export type CitiesAutocompleteResponse = City[];

export const getCitiesAutocomplete = createAsyncThunk(
  'data/getCitiesAutocomplete',
  async (
    { term, state, zipCode }: { term?: string; state?: string; zipCode?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '10' });
    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (state !== '' && state != null) {
      searchParams.append('state', state);
    }
    if (zipCode !== '' && zipCode != null) {
      searchParams.append('zipCodes', zipCode);
    }

    const url = `city/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosThunk.get(url);
      return response.data as CitiesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type State = {
  id: number;
  name: string;
};

export type StatesAutocompleteResponse = State[];

export const getStatesAutocomplete = createAsyncThunk(
  'data/getStatesAutocomplete',
  async (
    { term, city, zipCode }: { term?: string; city?: string; zipCode?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '50' });

    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (city !== '' && city != null) {
      searchParams.append('city', city);
    }
    if (zipCode !== '' && zipCode != null) {
      searchParams.append('zipCodes', zipCode);
    }
    const url = `state/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosThunk.get(url);
      return response.data as StatesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type ZipCode = {
  id: number;
  name: string;
};

export type ZipCodesAutocompleteResponse = ZipCode[];

export const getZipCodesAutocomplete = createAsyncThunk(
  'data/getZipCodesAutocomplete',
  async (
    { term, city, state }: { term?: string; city?: string; state?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '10' });

    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (city !== '' && city != null) {
      searchParams.append('city', city);
    }

    if (state !== '' && state != null) {
      searchParams.append('state', state);
    }
    const url = `zip-codes/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosThunk.get(url);
      return response.data as ZipCodesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
