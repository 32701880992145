import { RememberMe } from '@mui/icons-material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteLsBothTokens, setLsBothTokens } from '../../modules/networkTools/localStorageTokens';
import {
  postLoginUser,
  putLogoutUser,
  postRegisterUser,
  postChangePasswordUser,
  getResetPasswordCode,
  CitiesAutocompleteResponse,
  getCitiesAutocomplete,
  getStatesAutocomplete,
  getZipCodesAutocomplete,
  StatesAutocompleteResponse,
  ZipCodesAutocompleteResponse
} from './authActions';
import { notifyUserError, notifyUserSuccess } from '../../utils/notifications';
import {
  MSG_LOGIN_ERROR,
  MSG_SEND_RESET_CODE_SUCCESS_EMAIL,
  MSG_SEND_RESET_CODE_SUCCESS_PHONE
} from '../../utils/errorMessages';

type UserProps = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  rememberMe: boolean;
  resetPasswordBy: 'email' | 'text';
  code: string;
  street: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  dob: string;
};

export interface AuthSliceProps {
  user: UserProps;
  loading: boolean;
  success: boolean;
  error: string | null | undefined;
  citiesAutocomplete: {
    loading: boolean;
    data: CitiesAutocompleteResponse;
  };
  statesAutocomplete: {
    loading: boolean;
    data: StatesAutocompleteResponse;
  };
  zipCodesAutocomplete: {
    loading: boolean;
    data: ZipCodesAutocompleteResponse;
  };
}

const initialState: AuthSliceProps = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    rememberMe: true,
    resetPasswordBy: 'email',
    code: '',
    street: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    dob: ''
  },
  loading: false,
  error: null,
  success: false,
  citiesAutocomplete: {
    loading: false,
    data: []
  },
  statesAutocomplete: {
    loading: false,
    data: []
  },
  zipCodesAutocomplete: {
    loading: false,
    data: []
  }
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    resetStatuses: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
    updateUserData: (state, action: PayloadAction<UserProps>) => {
      state.user = { ...state.user, ...action.payload };
    },

    logout: (state) => {
      deleteLsBothTokens();
      state.user = { ...state.user, email: '', password: '' };
      state.success = false;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder //getResetPasswordCode
      .addCase(getResetPasswordCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getResetPasswordCode.fulfilled, (state: any, { payload }: any) => {
        // notifyUserError(MSG_SEND_RESET_CODE_SUCCESS); TODO Doesnt Notify

        notifyUserSuccess(
          payload === 'email'
            ? MSG_SEND_RESET_CODE_SUCCESS_EMAIL
            : MSG_SEND_RESET_CODE_SUCCESS_PHONE
        );
        state.loading = false;
        state.success = true;
      })
      .addCase(getResetPasswordCode.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.[0].message;
        notifyUserError(payload);
      }) //post postChangePasswordUser
      .addCase(postChangePasswordUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postChangePasswordUser.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postChangePasswordUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.[0].message;
        notifyUserError(payload);
      }) //post postRegisterUser
      .addCase(postRegisterUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postRegisterUser.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postRegisterUser.rejected, (state, { error, payload }) => {
        state.loading = false;
        state.error = error.message;
        notifyUserError(payload);
      }) //postLoginUser
      .addCase(postLoginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postLoginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        if (payload.accessToken && payload.refreshToken) {
          setLsBothTokens(payload.accessToken, payload.refreshToken);
        }
      })
      .addCase(postLoginUser.rejected, (state, { error, payload }) => {
        state.loading = false;
        state.error = payload?.[0]?.message;
        notifyUserError(MSG_LOGIN_ERROR);
      }) //putLogoutUser
      .addCase(putLogoutUser.rejected, (_, { payload }) => {
        notifyUserError(payload);
      })
      // Cities
      .addCase(getCitiesAutocomplete.pending, (state) => {
        state.citiesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getCitiesAutocomplete.fulfilled, (state, action) => {
        state.citiesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getCitiesAutocomplete.rejected, (state, payload: any) => {
        notifyUserError(payload);
      })
      // States
      .addCase(getStatesAutocomplete.pending, (state) => {
        state.statesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getStatesAutocomplete.fulfilled, (state, action) => {
        state.statesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getStatesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // ZIP Codes
      .addCase(getZipCodesAutocomplete.pending, (state) => {
        state.zipCodesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getZipCodesAutocomplete.fulfilled, (state, action) => {
        state.zipCodesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getZipCodesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      });
  }
});

export const { updateUserData, resetStatuses, logout } = authSlice.actions;

export default authSlice.reducer;
