import React, { FC } from 'react';
import { Title } from '../title';
import { Drawer, SvgIcon } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout_icon.svg';
import style from './sidebarBottom.module.scss';
import { putLogoutUser } from '../../store/auth/authActions';
import { getLsRefreshToken } from '../../modules/networkTools/localStorageTokens';
import { useAppDispatch } from '../../store/hooks';

interface Props {
  children: JSX.Element;
  title: string;
  onClose: () => void;
  onLogout?: boolean;
  open: boolean;
}

const SidebarBottom: FC<Props> = ({ children, title, onClose, onLogout, open }) => {
  const dispatch = useAppDispatch();

  const onHandleLogout = () => {
    const lsRefreshToken = getLsRefreshToken();
    if (lsRefreshToken) {
      dispatch(putLogoutUser(lsRefreshToken));
    }
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose} className={style.sidebarBottom}>
      <div className={style.titleWrap}>
        <Title text={title} type="h2" />
        <Close className={style.closeIcon} onClick={onClose} />
      </div>
      {onLogout && (
        <div className={style.logout} onClick={onHandleLogout}>
          <SvgIcon component={LogoutIcon} inheritViewBox className={style.logoutIcon} />
          Log out
        </div>
      )}
      <div>{children}</div>
    </Drawer>
  );
};

export default SidebarBottom;
