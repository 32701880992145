//Please use format MSG_[message]_[name]
export const MSG_LOGIN_SUCCESS = 'Log in was successful.';
export const MSG_LOGIN_ERROR = 'Email or Password is invalid. Please check';
export const MSG_REGISTRATION_SUCCESS = 'Registration was successful.';

export const MSG_SEND_RESET_CODE_SUCCESS_EMAIL =
  'Request code sent to your email. Check the code there and enter it into this form.';
export const MSG_SEND_RESET_CODE_SUCCESS_PHONE =
  'Request code sent to your phone. Check the code there and enter it into this form.';

export const MSG_VALID_PHONE_FORMAT = 'Please use valid phone format (123) 456-7891';
export const MSG_VALID_PHONE_AND_EMAIL_FORMAT = 'Please use valid Email/Phone (123) 456-7891';

export const MSG_FIELD_LESS_100_CHARS = 'Field must be less then 100 characters.';
export const MSG_FIELD_LESS_40_CHARS = 'Field must be less then 40 characters.';
export const MSG_FIELD_LESS_13_CHARS = 'Field must be less then 13 characters.';
export const MSG_ENGLISH_LETTERS_ONLY = 'Please use English letters only.';
export const MSG_NUMBERS_ONLY = 'Please use numbers only.';
export const MSG_WHITE_SPACE_ONLY = 'Field cannot contain only spaces.';

export const displayErrorStyling = (
  isFieldTouched: boolean | undefined,
  error: string | undefined
) => {
  if (!isFieldTouched) return '';
  if (isFieldTouched && error) return 'globalErrorBorder';
  if (isFieldTouched && !error) return 'globalValidBorder';
};
