import React, { FC } from 'react';
import { Avatar, SvgIcon } from '@mui/material';
import {
  CakeOutlined,
  LocationOnOutlined,
  MailOutline,
  PhoneAndroidOutlined
} from '@mui/icons-material';

import { ReactComponent as EditIcon } from '../../../assets/svg/edit_icon.svg';
import style from './profile.module.scss';
import { useAppSelector } from '../../../store/hooks';

interface Props {
  setEdit: () => void;
}

const ProfileInfo: FC<Props> = ({ setEdit }) => {
  const profileState = useAppSelector((state) => state.profile);
  const profile = profileState.profile;

  if (!profile) return null;

  return (
    <div className={style.profile}>
      <Avatar className={style.avatar}>{profile.firstName[0]}</Avatar>
      <div className={style.profileWrap}>
        <div className={style.titleWrap}>
          <div className={style.name}>
            {profile.firstName} {profile.lastName}
          </div>
          <div className={style.edit} onClick={setEdit}>
            <SvgIcon component={EditIcon} inheritViewBox className={style.editIcon} />
            Edit
          </div>
        </div>
        <div className={style.text}>
          <MailOutline className={style.locationIcon} />
          <p>{profile.email}</p>
        </div>
        <div className={style.text}>
          <div className={style.location}>
            <LocationOnOutlined className={style.locationIcon} />
            <div>
              <p>{profile.street},</p>
              <p>
                {profile.addressLine2 ? `${profile.addressLine2}, ` : ''}
                {profile.city},
              </p>
              <p>
                {profile.state}, {profile.zip}
              </p>
            </div>
          </div>
        </div>
        <div className={style.text}>
          <CakeOutlined className={style.locationIcon} />
          <p>{profile.dob}</p>
        </div>
        <div className={style.text}>
          <PhoneAndroidOutlined className={style.locationIcon} />
          <p>{profile.phone}</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
