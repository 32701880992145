import clsx from 'clsx';
import * as React from 'react';
import style from './breadcrumbBack.module.scss';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

interface ButtonProps {
  handleOnClick?: React.MouseEventHandler<HTMLButtonElement | undefined> | undefined;
}

export const BreadcrumbBack: React.FunctionComponent<ButtonProps> = ({ handleOnClick }) => {
  return (
    <button
      className={style.breadcrumbContainer}
      onClick={(e) => handleOnClick && handleOnClick(e)}
    >
      <ArrowBackIosNewIcon /> Back
    </button>
  );
};

export default BreadcrumbBack;
