import { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SidebarBottom } from '../sidebarBottom';
import { Popper } from '../popper';

const ResponsiveSidebarPopperChecker: FC<any> = (props) => {
  const matches = useMediaQuery('@media (max-width:1024px)');
  if (matches) {
    return <SidebarBottom {...props}>{props.children}</SidebarBottom>;
  }
  return <Popper {...props}>{props.children}</Popper>;
};

export default ResponsiveSidebarPopperChecker;
