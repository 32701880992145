import React, { useState } from 'react';
import style from './passwordSuggestion.module.scss';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import clsx from 'clsx';

type Step2SignUpProps = {
  untouchedState?: boolean;
  isMissingDigit: boolean;
  isMissingBigSmallLetter: boolean;
  isMissingSymbol: boolean;
};

const PasswordSuggestion: React.FunctionComponent<Step2SignUpProps> = ({
  untouchedState,
  isMissingDigit,
  isMissingSymbol,
  isMissingBigSmallLetter
}) => {
  return (
    <div className={style.pageContainer}>
      <div className={style.passwordSuggestions}>
        <p
          className={clsx(
            !untouchedState && isMissingBigSmallLetter && style.errorLine,
            !untouchedState && !isMissingBigSmallLetter && style.validLine
          )}
        >
          <CheckCircleOutlineOutlinedIcon /> At least 1 uppercase letter and 1 lowercase letter
        </p>
        <p
          className={clsx(
            !untouchedState && isMissingDigit && style.errorLine,
            !untouchedState && !isMissingBigSmallLetter && style.validLine
          )}
        >
          <CheckCircleOutlineOutlinedIcon /> At least 1 digit
        </p>
        <p
          className={clsx(
            !untouchedState && isMissingSymbol && style.errorLine,
            !untouchedState && !isMissingBigSmallLetter && style.validLine
          )}
        >
          <CheckCircleOutlineOutlinedIcon /> At least 1 symbol{' '}
          {'(!”#$%&’()*+,-./:;<¸=>?@[]^_’{|}~)'}
        </p>
      </div>
    </div>
  );
};

export default PasswordSuggestion;
