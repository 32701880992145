import { axiosPublic } from './index';
import { deleteLsBothTokens, updateLsAccessToken } from './localStorageTokens';

export const apiRefreshToken = async (
  _accessToken: string | null,
  _refreshToken: string | null
) => {
  try {
    const response = await axiosPublic.post(
      'login/refresh',
      {
        refreshToken: _refreshToken
      },
      { headers: { Authorization: `Bearer ${_accessToken}` } }
    );

    const refreshedToken = response.data.accessToken;
    updateLsAccessToken(refreshedToken);

    return refreshedToken;
  } catch (error: any) {
    //REFRESH TOKEN EXPIRED
    if (
      error.response.status === 401 ||
      error.response.status === 500 ||
      error.response.status === 503
    ) {
      // logout if refresh not possible
      deleteLsBothTokens();
      window.location.reload();
      return null;
    }
  }
};

export default apiRefreshToken;
