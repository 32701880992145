import clsx from 'clsx';
import * as React from 'react';
import style from './loadingSpinner.module.scss';
import CircleLoader from 'react-spinners/CircleLoader';

const LoadingSpinner: React.FunctionComponent<any> = ({}) => {
  return <CircleLoader color={'#219ebc'} className={style.spinnerColor} />;
};

export default LoadingSpinner;
