import clsx from 'clsx';
import * as React from 'react';
import style from './inputField.module.scss';
import { VisibilityController } from './visibilityController';

interface InputFieldProps {
  key?: string;
  id?: string;
  name?: string;
  value: any;
  classnamesProps?: any;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'checkbox' | 'number';
  onBlur: any;
  onChange: any;
  onFocus?: any;
  visibilityControl?: boolean;
  autoFocus?: boolean;
  isValid?: boolean | string | undefined;
  tags?: React.ReactNode;
  autoComplete?: string;
  disabled?: boolean;
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      key,
      id,
      name,
      value,
      placeholder,
      type,
      classnamesProps,
      onBlur,
      onChange,
      onFocus,
      visibilityControl = false,
      autoFocus = false,
      isValid = true,
      tags,
      autoComplete,
      disabled
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(true);
    const handleVisibilityControl = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div
        className={clsx(
          style.inputContainer,
          type === 'password' && style.passwordDotsStyling,
          type === 'password' && !isValid && style.passwordErrorDotsStyling,

          visibilityControl && style.extendInputContainer
        )}
      >
        {tags && <div className={style.tags}>{tags}</div>}
        <input
          disabled={disabled}
          ref={ref}
          key={key}
          autoFocus={autoFocus}
          id={id}
          value={value}
          className={clsx(
            style.defaultStyle,
            type === 'password' && style.passwordInputStyling,
            classnamesProps
          )}
          name={name}
          type={showPassword ? type : 'text'}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autoComplete}
        />
        {visibilityControl && (
          <div
            className={clsx(style.visibilityIconContainer)}
            onClick={() => handleVisibilityControl()}
          >
            <VisibilityController visible={showPassword} />
          </div>
        )}
      </div>
    );
  }
);

export default InputField;
