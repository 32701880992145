import jwt_decode from 'jwt-decode';
import { getLsAccessToken } from '../../modules/networkTools/localStorageTokens';
import { useAppSelector } from '../../store/hooks';
import { isTokenExpired } from './tokenValidity';

export const useUserAuthStatusChecker = () => {
  const authState = useAppSelector((state) => state.auth);
  const token = getLsAccessToken();

  if (token === null) return;
  const decoded: any = jwt_decode(token);
  const userGroups: any = decoded['cognito:groups'] || [];

  isTokenExpired(token);

  return { token: true, roles: userGroups };
};

export const getUserInfoFRomDEcodedToken = () => {
  const token: any = getLsAccessToken();

  const decoded: any = jwt_decode(token);
  console.log('decoded: ', decoded);

  return { userInfo: decoded };
};
