import { createSlice } from '@reduxjs/toolkit';
import { getReferenceLink, getTotalAwards } from './dashboardActions';
import { notifyUserError } from '../../utils/notifications';

export interface DashboardSliceProps {
  reference: {
    referenceLink: string | null;
    host: string;
  };
  amounts: {
    applicationsAmount: number;
    screeningAmount: number;
    hiringAmount: number;
    total: number;
  } | null;
}

const initialState: DashboardSliceProps = {
  reference: {
    referenceLink: null,
    host: ''
  },
  amounts: null
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReferenceLink.fulfilled, (state: any, { payload }: any) => {
        state.reference = payload;
      })
      .addCase(getReferenceLink.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getTotalAwards.fulfilled, (state: any, { payload }: any) => {
        state.amounts = payload;
      })
      .addCase(getTotalAwards.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export default dashboardSlice.reducer;
