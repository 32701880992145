import React, { FC, useState } from 'react';
import { ResponsiveSidebarPopperChecker } from '../../../components';
import ProfileInfo from './profileInfo';
import ProfileForm from './profileForm';
import AwardsHistory from './awardsHistory';

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  open: boolean;
}

const ProfilePopper: FC<Props> = ({ anchorEl, onClose, open }) => {
  const [edit, setEdit] = useState(false);

  return (
    <ResponsiveSidebarPopperChecker
      open={open}
      anchorEl={anchorEl}
      title="My Profile"
      onLogout={true}
      onClose={onClose}
    >
      <>
        {edit ? (
          <ProfileForm setEdit={() => setEdit(false)} />
        ) : (
          <ProfileInfo setEdit={() => setEdit(true)} />
        )}
        <AwardsHistory />
      </>
    </ResponsiveSidebarPopperChecker>
  );
};

export default ProfilePopper;
