import clsx from 'clsx';
import * as React from 'react';
import style from './modal.module.scss';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
  keepMounted?: boolean;
  customClassInsideContainer?: any;
  customClassOutsideContainer?: any;
  handleClose?: any;
  open: boolean;
  children: JSX.Element;
}

const ModalPopup: React.FunctionComponent<ModalProps> = ({
  keepMounted = false,
  customClassInsideContainer,
  customClassOutsideContainer,
  open,
  handleClose,
  children
}) => {
  return (
    <>
      <Modal
        keepMounted={keepMounted}
        className={clsx(style.defaultModalMainContainer, customClassOutsideContainer)}
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal"
        aria-describedby="parent-modal-description"
      >
        <div className={clsx(style.defaultModalInsideContainer, customClassInsideContainer)}>
          <div className={style.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </div>
          {React.cloneElement(children, { onClose: handleClose })}
        </div>
      </Modal>
    </>
  );
};

export default ModalPopup;
