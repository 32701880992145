import { FormControlLabel, Radio, RadioGroup, SvgIcon } from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { ReactComponent as MailIcon } from '../../assets/svg/mail_icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/svg/phone_icon.svg';
import { LoadingSpinner, Title } from '../../components';
import { getCandidateCV, getCandidateVideo } from '../../store/candidate/candidateActions';
import { CandidateProfileDTO } from '../../store/candidate/candidateSlice';
import { useAppDispatch } from '../../store/hooks';
import style from './candidateSidebar.module.scss';
const DIRECTION = {
  CV: 'CV',
  VIDEO: 'Video'
};

interface Props {
  candidateProfile: CandidateProfileDTO;
  isOpen: boolean;
  onClose: () => void;
  candidateId: string;
}

const CandidateSidebar: FC<Props> = ({ candidateProfile, isOpen, onClose, candidateId }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<any>({
    loading: false,
    direction: null
  });

  const onDownloadUrl = (url: string, fileName: string, direction: string) => {
    setIsLoading({
      loading: true,
      direction
    });
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      setIsLoading({
        loading: false,
        direction: null
      });
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const onDownloadCV = () => {
    dispatch(getCandidateCV(candidateId)).then(({ payload }: any) =>
      onDownloadUrl(payload.url, `resume.${payload.extension}`, DIRECTION.CV)
    );
  };

  const onDownloadVideo = () => {
    dispatch(getCandidateVideo(candidateId)).then(({ payload }: any) =>
      onDownloadUrl(payload.url, `video.${payload.extension}`, DIRECTION.VIDEO)
    );
  };

  const {
    name,
    email,
    phone,
    educationList,
    workExperienceList,
    references,
    areComfortableVisitingPeople,
    background,
    cvFileName,
    videoFileName,
    howDidYouHearAboutUs,
    languages,
    campaign
  }: any = candidateProfile;

  return (
    <div className={style.sidebarContainer}>
      <>
        <div className={style.sidebarItem}>
          <div className={style.title}>
            <div className={style.titleWrap}>
              <Title text="Contact information" type="h2" />
            </div>
          </div>
          <div className={style.contact}>
            <SvgIcon component={PhoneIcon} inheritViewBox className={style.iconContact} />
            <a href={`tel:${phone}`}> {phone}</a>
          </div>
          <div className={style.contact}>
            <SvgIcon component={MailIcon} inheritViewBox className={style.iconContact} />
            <a href={`mailto: ${email}`}>{email}</a>
          </div>
          <div className={style.education}>
            <div className={style.subtitle}>Languages</div>
            {languages?.map((x: any, i: number) => {
              return `${x?.name} ${i !== languages.length - 1 ? ',' : ''} `;
            })}
          </div>
          {campaign?.name && (
            <div className={style.education}>
              <div className={style.subtitle}>Region</div>
              {campaign?.name}
            </div>
          )}
        </div>
        {(videoFileName || background) && (
          <div className={style.sidebarItem}>
            <div className={style.title}>
              <Title text="Background" type="h2" />
              {(!isLoading.loading || isLoading.direction === DIRECTION.CV) && videoFileName && (
                <button className={style.buttonDownload} onClick={onDownloadVideo}>
                  Download video
                </button>
              )}
              {isLoading.loading && isLoading.direction === DIRECTION.VIDEO && (
                <div className={style.loadingArea}>
                  <LoadingSpinner />
                </div>
              )}
            </div>
            {background && <div className={style.contact}>{background}</div>}
          </div>
        )}
        {(cvFileName || (educationList && educationList.length > 0)) && (
          <div className={style.sidebarItem}>
            <div className={style.title}>
              <Title text="Resume" type="h2" />
              {(!isLoading.loading || isLoading.direction === DIRECTION.VIDEO) && cvFileName && (
                <button
                  className={clsx(style.buttonDownload, style.buttonDownloadCV)}
                  onClick={onDownloadCV}
                >
                  Download resume
                </button>
              )}
              {isLoading.loading && isLoading.direction === DIRECTION.CV && (
                <div className={style.loadingArea}>
                  <LoadingSpinner />
                </div>
              )}
            </div>
            {educationList && educationList.length > 0 && (
              <div className={style.education}>
                <div className={style.subtitle}>Education</div>
                {educationList?.map((item: any, index: number) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            )}
            {workExperienceList && workExperienceList.length > 0 && (
              <div className={style.education}>
                <div className={style.subtitle}>Work/Volunteer experience</div>
                {workExperienceList?.map((item: any, index: number) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            )}
          </div>
        )}
        {references && references.length > 0 && (
          <div className={style.sidebarItem}>
            <div className={style.title}>
              <Title text="References" type="h2" />
            </div>
            <div className={style.education}>
              {references.map((item: any, index: number) => (
                <p key={index}>
                  <b>{item.name}</b>, {item.company}, {item.relationship}
                  <span>
                    {item.phoneOrEmail.includes('@') ? (
                      <SvgIcon component={MailIcon} inheritViewBox className={style.iconContact} />
                    ) : (
                      <SvgIcon component={PhoneIcon} inheritViewBox className={style.iconContact} />
                    )}
                    <a
                      href={
                        item.phoneOrEmail.includes('@')
                          ? `mailto:${item.phoneOrEmail}`
                          : `tel:${item.phoneOrEmail}`
                      }
                    >
                      {' '}
                      {item.phoneOrEmail}
                    </a>
                  </span>
                </p>
              ))}
            </div>
          </div>
        )}
        <div className={style.sidebarItem}>
          <div className={style.title}>
            <Title text="Quick questions" type="h2" />
          </div>
          <div className={style.education}>
            <div className={style.subtitle}>
              CHWs visit people in their homes. Are you comfortable doing home visits?
            </div>
            <RadioGroup row aria-labelledby="comfortable-radio" className={style.radioGroup}>
              <FormControlLabel
                checked={areComfortableVisitingPeople}
                control={<Radio />}
                disabled
                label="yes"
              />
              <FormControlLabel
                checked={!areComfortableVisitingPeople}
                control={<Radio />}
                disabled
                label="no"
              />
            </RadioGroup>
          </div>
          <div className={style.education}>
            <div className={style.subtitle}>How did you hear about us?</div>
            {howDidYouHearAboutUs}
          </div>
        </div>
      </>
    </div>
  );
};

export default CandidateSidebar;
