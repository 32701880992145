import {
  Pagination,
  SvgIcon,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMaterial,
  TablePagination,
  TableRow
} from '@mui/material';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrorDown_icon.svg';
import { LoadingSpinner } from '../loadingSpinner';
import style from './table.module.scss';

interface HeadCell {
  id: string;
  label: string;
  sort?: boolean;
}

enum SortValues {
  ASC = 'asc',
  DESC = 'desc'
}

interface Props {
  isLoading: boolean;
  columns: any;
  headCells: HeadCell[];
  SortValuesTitles: string[];
  totalElements: number;
  page: number;
  size: number;
  getTableData: any;
}

const Table: FC<Props> = ({
  columns,
  isLoading,
  headCells,
  SortValuesTitles,
  totalElements,
  page,
  size,
  getTableData
}) => {
  const [sortConfig, setSortConfig] = useState(
    SortValuesTitles.map<any>((item) => ({
      title: item,
      direction: null
    }))
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    getTableData({
      page: newPage - 1,
      size: size,
      sort: sortConfig
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    getTableData({
      page: 0,
      size: parseInt(event.target.value, 10),
      sort: sortConfig
    });
  };

  const sort = (itemSort: any) => {
    if (!itemSort) return false;

    const data = sortConfig.map((item) => {
      if (item.title === itemSort) {
        let directionData: string | null = SortValues.ASC;
        if (item.direction === SortValues.ASC) {
          directionData = SortValues.DESC;
        }
        if (item.direction === SortValues.DESC) {
          directionData = null;
        }
        return {
          ...item,
          direction: directionData
        };
      }
      return item;
    });
    setSortConfig(data);
    getTableData({
      page: page,
      size: size,
      sort: data
    });
  };

  return (
    <div className={style.tableWrap}>
      <TableContainer>
        <TableMaterial>
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  className={item.sort ? style.pointer : ''}
                  onClick={() => item.sort && sort(item.id)}
                  key={item.id}
                >
                  <span className={style.thWrapper}>
                    {item.label}
                    {item.sort && (
                      <span className={style.iconWrapper}>
                        {sortConfig.find((sortItem) => sortItem.title === item.id).direction !==
                          SortValues.DESC && (
                          <SvgIcon
                            component={ArrowDownIcon}
                            inheritViewBox
                            className={style.icon}
                          />
                        )}
                        {sortConfig.find((sortItem) => sortItem.title === item.id).direction !==
                          SortValues.ASC && (
                          <SvgIcon
                            component={ArrowDownIcon}
                            inheritViewBox
                            className={clsx(style.icon, style.activeIcon)}
                          />
                        )}
                      </span>
                    )}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {columns.length > 0 && columns.map((item: any) => item)}
            {columns.length === 0 && !isLoading && (
              <TableRow>
                <TableCell>no data</TableCell>
                {headCells
                  .filter((item, index) => index > 0)
                  .map((item) => (
                    <TableCell key={item.id} />
                  ))}
              </TableRow>
            )}
          </TableBody>
        </TableMaterial>
      </TableContainer>
      {isLoading && !columns.length && (
        <div className={style.spinner}>
          <LoadingSpinner />
        </div>
      )}
      {columns.length > 0 && (
        <div className={style.paginationWrap}>
          <TablePagination
            className={style.pagination}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() => false}
          />
          <Pagination
            className={style.paginationNumbers}
            count={Math.ceil(totalElements / size)}
            page={page + 1}
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      )}
    </div>
  );
};

export default Table;
