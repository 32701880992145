import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import authSlice from './auth/authSlice';
import candidateSlice from './candidate/candidateSlice';
import dashboardSlice from './dashboard/dashboardSlice';
import profileSlice from './profile/profileSlice';
import { isEnvProduction } from '../utils/isenvProduction';

const combinedReducer = combineReducers({
  auth: authSlice,
  dashboard: dashboardSlice,
  profile: profileSlice,
  candidate: candidateSlice
});

const store = configureStore({
  reducer: combinedReducer,
  devTools: !isEnvProduction(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: {},
      immutableCheck: false
    })
  //.concat(logger)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
