import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import style from './navbar.module.scss';

interface NavItem {
  path: string;
  name: string;
}

interface Props {
  navItems: NavItem[];
}

const NavBar: FC<Props> = ({ navItems }) => {
  const location = useLocation().pathname;

  return (
    <div className={style.navbar}>
      {navItems.map((item) => (
        <Link
          className={clsx(style.item, location === item.path && style.active)}
          key={item.name}
          to={item.path}
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
};

export default NavBar;
