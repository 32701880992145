import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from '../../modules/networkTools';

export const getReferenceLink: any = createAsyncThunk(
  'dashboard/referenceLink',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('recruiter/dashboard/reference/link');

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalAwards: any = createAsyncThunk(
  'dashboard/totalAwards',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('recruiter/dashboard/total-awards');
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
