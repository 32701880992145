import * as React from 'react';
import style from './referalLink.module.scss';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import { Title } from '../../components';
import { getReferenceLink } from '../../store/dashboard/dashboardActions';
import { CANDIDATE_CREATE_PAGE_URL } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface Props {}

const ReferalLink: React.FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const { reference } = useAppSelector((state) => state.dashboard);
  const { referenceLink } = reference;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    dispatch(getReferenceLink());
  }, []);

  return (
    <div className={style.pageContainer}>
      <div className={style.dashBoardTitle}>
        <Title type="h0" text="Refer and Earn!" />
        <Title
          type="h4"
          text="Do you know someone who does good without being asked? IMPaCT is hiring people with a gift of caring as full-time Community Health Workers."
        />
      </div>
      <div className={style.background}>
        {referenceLink && (
          <div className={style.refLInkContainer}>
            {!copied ? (
              <CopyToClipboard
                text={
                  `${window.location.origin}${CANDIDATE_CREATE_PAGE_URL}/${referenceLink}` || ''
                }
                onCopy={() => setCopied(true)}
              >
                <button className={style.refLinkButton}>Copy Referral Link</button>
              </CopyToClipboard>
            ) : (
              <div className={style.refLinkStringWrapper}>
                <div className={style.refLinkString}>
                  {window.location.origin}
                  {CANDIDATE_CREATE_PAGE_URL}/{referenceLink}
                </div>
                <button className={style.refLinkButtonCopied}>
                  <CheckIcon />
                  Copied&nbsp;
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferalLink;
