import clsx from 'clsx';
import * as React from 'react';
import style from './button.module.scss';

interface ButtonProps {
  handleOnClick?: () => void;
  isSecondary?: boolean;
  disabled?: boolean;
  text?: string;
  customStyleClass?: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  handleOnClick,
  disabled,
  isSecondary,
  text,
  customStyleClass,
  type
}) => {
  return (
    <>
      <button
        onClick={handleOnClick}
        disabled={disabled}
        type={type}
        className={clsx(
          !isSecondary && style.buttonStyle,
          isSecondary && style.buttonStyleSecondary,
          customStyleClass
        )}
      >
        {text}
      </button>
    </>
  );
};

export default Button;
