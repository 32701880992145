import { toast } from 'react-toastify';
import { components } from '../../api/generated-types';

export const notifyUserSuccess = (message: string) => {
  return toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  });
};
export const notifyUserError = (
  errorMessage: components['schemas']['ErrorListResponse']['errors'] | string | undefined
) => {
  let error: string | undefined;
  if (typeof errorMessage === 'string') {
    error = errorMessage;
  } else if (errorMessage === undefined) {
    error = 'Something went wrong';
  } else if (Array.isArray(errorMessage)) {
    const errorMessagesFromArray = errorMessage.map(function (err) {
      return err.message;
    });

    error = errorMessagesFromArray?.join(' ');
  }

  return toast.error(error, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  });
};
