import React, { FC } from 'react';
import clsx from 'clsx';
import { Title } from '../title';
import { Popper as PopperMaterial, ClickAwayListener, SvgIcon } from '@mui/material';
import { putLogoutUser } from '../../store/auth/authActions';
import { getLsRefreshToken } from '../../modules/networkTools/localStorageTokens';
import { Close } from '@mui/icons-material';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout_icon.svg';
import style from './popper.module.scss';
import { useAppDispatch } from '../../store/hooks';

interface Props {
  anchorEl: null | HTMLElement;
  title?: string;
  children: JSX.Element;
  closeIcon?: boolean;
  onClose: () => void;
  onLogout?: boolean;
  open: boolean;
  id?: number;
  placement?:
    | 'auto-end'
    | 'auto-start'
    | 'auto'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  customClassName?: any;
}

const Popper: FC<Props> = ({
  anchorEl,
  title,
  children,
  closeIcon,
  onLogout,
  onClose,
  open,
  placement,
  customClassName
}) => {
  const dispatch = useAppDispatch();

  const onHandleLogout = () => {
    const lsRefreshToken = getLsRefreshToken();
    if (lsRefreshToken) {
      dispatch(putLogoutUser(lsRefreshToken));
    }
  };

  return (
    <PopperMaterial
      open={open}
      anchorEl={anchorEl}
      disablePortal={true}
      className={style.popper}
      placement={placement || 'bottom'}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div className={clsx(style.content, customClassName)}>
          {title && (
            <div className={style.header}>
              <Title type="h1" text={title} />
              {closeIcon && <Close className={style.closeIcon} onClick={onClose} />}
              {onLogout && (
                <div className={style.logout} onClick={onHandleLogout}>
                  <SvgIcon component={LogoutIcon} inheritViewBox className={style.logoutIcon} />
                  Log out
                </div>
              )}
            </div>
          )}
          <div>{children}</div>
        </div>
      </ClickAwayListener>
    </PopperMaterial>
  );
};

export default Popper;
