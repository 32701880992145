import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import React, { PureComponent } from 'react';
import style from './step3SignUp.module.scss';
import * as Yup from 'yup';
import { InputField } from '../../../components';
import { Button } from '../../../components';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { AuthSliceProps, updateUserData } from '../../../store/auth/authSlice';

import { BreadcrumbBack } from '../../../components';
import { Logo } from '../../../components';

type Step3SignUpProps = {
  handleClickOnNextBtn?: () => void;
  handleClickOnBackBtn?: () => void;
  message: string;
};

const Step3SignUp: React.FunctionComponent<Step3SignUpProps> = ({
  handleClickOnNextBtn,
  handleClickOnBackBtn,
  message
}) => {
  return (
    <div className={style.container}>
      <div className={style.inputGroup}>
        <div className={style.congratsContainer} />
        <div className={style.inputGroupH1AndText}>
          <h1>Congrats!</h1>
          <p>{message}</p>
        </div>
      </div>

      {/* next button */}
      <div className={style.inputGroup}>
        <Button
          isSecondary
          text={'Log In'}
          type={'submit'}
          handleOnClick={() => handleClickOnNextBtn && handleClickOnNextBtn()}
        />
      </div>
    </div>
  );
};

export default Step3SignUp;
