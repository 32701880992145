import React, { FC, useEffect } from 'react';
import { AmountProgress } from '../../components';
import style from './amounts.module.scss';
import { getTotalAwards } from '../../store/dashboard/dashboardActions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface Props {}

const Amounts: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const dashBoardState = useAppSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getTotalAwards());
  }, []);

  function numberWithCommas(x: number) {
    return x.toString().replace(/.(?=(?:.{3})+$)/g, '$&,');
  }

  if (!dashBoardState.amounts) return null;

  const { applicationsAmount, hiringAmount, screeningAmount, total } = dashBoardState.amounts;

  return (
    <>
      <div className={style.amountsWrapper}>
        <div className={style.titleWrapper}>
          <div className={style.titleWrap}>
            <div className={style.title}>You’ve earned</div>
            <div className={style.amount}>$ {numberWithCommas(total)}</div>
          </div>
        </div>
        {dashBoardState.amounts && (
          <div className={style.progressWrap}>
            <AmountProgress title="Applications" amount={applicationsAmount} fullAmount={total} />
            <AmountProgress title="Screening" amount={screeningAmount} fullAmount={total} />
            <AmountProgress title="Hiring" amount={hiringAmount} fullAmount={total} />
          </div>
        )}
      </div>
    </>
  );
};

export default Amounts;
