import clsx from 'clsx';
import * as React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import style from './visibilityController.module.scss';
interface InputFieldProps {
  visible: boolean;
}

const VisibilityController: React.FunctionComponent<InputFieldProps> = ({ visible }) => {
  return (
    <div className={style.IconStyling}>{visible ? <VisibilityIcon /> : <VisibilityOffIcon />}</div>
  );
};

export default VisibilityController;
