import { Chip, SvgIcon } from '@mui/material';
import React, { ComponentProps } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/svg/close_icon.svg';

type Props = {
  label: string;
  onDelete?: (event: any) => void;
} & ComponentProps<typeof Chip>;

const Tag = ({ onDelete, label, size = 'small', ...props }: Props) => {
  return (
    <Chip
      {...props}
      sx={{
        backgroundColor: '#EFF9FB',
        borderRadius: '4px',
        border: '1px solid #5EBBCE',
        fontFamily: '"Nunito", sans-serif',
        height: '25px',
        '& .MuiChip-label': {
          fontSize: size === 'small' ? '14px' : '16px',
          padding: '0 8px'
        },
        '& .MuiChip-deleteIcon': {
          width: '8px'
        },
        ...props.sx
      }}
      deleteIcon={<SvgIcon onClick={onDelete} component={CloseIcon} inheritViewBox />}
      label={label}
      onDelete={onDelete}
    />
  );
};

export default Tag;
