import axios from 'axios';
import { apiRefreshToken } from './useRefreshToken';
import { getLsAccessToken, getLsRefreshToken } from './localStorageTokens';

const userAccessTokenInitial = getLsAccessToken();

export const axiosPublic = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: { Authorization: `Bearer ${userAccessTokenInitial}` }
});
//initial req, when authorization header is not set
const requestIntercept = axiosPrivate.interceptors.request.use(
  (config: any) => {
    config.headers = {
      Authorization: `Bearer ${getLsAccessToken()}`,
      'Content-Type': 'application/json'
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseIntercept = axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;

    //is 401 and  prev request was not sent to prevent loop - retry only once
    if (error?.response?.status === 401 && !originalRequest?.sent) {
      originalRequest.sent = true;

      const userRefreshToken = getLsRefreshToken();
      const userAccessToken = getLsAccessToken();
      const newAccessToken = await apiRefreshToken(userAccessToken, userRefreshToken);

      originalRequest.headers = { Authorization: `Bearer ${newAccessToken}` };
      return axiosPrivate(originalRequest);
    }

    return Promise.reject(error);
  }
);
