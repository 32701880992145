import {
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  checkboxClasses
} from '@mui/material';
import clsx from 'clsx';
import { InputField } from 'components/inputField';
import React, { ChangeEvent, ComponentProps, FunctionComponent, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrorDown_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close_icon.svg';
import { Tag } from '../tag';
import style from './dropdown.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/svg/search_icon.svg';

interface DropdownData {
  id: number | string;
  name: string;
  category?: string;
}

type Type = 'default' | 'success' | 'warning';

type Size = 'normal' | 'small';

interface DropdownProps {
  ref?: string;
  key?: string;
  id?: string;
  name?: string;
  data: readonly DropdownData[];
  label?: string;
  multiSelect?: boolean;
  value: string | string[];
  disabled?: boolean;
  rounded?: boolean;
  className?: string;
  sx?: ComponentProps<typeof Select>['sx'];
  type?: Type;
  size?: Size;
  onBlur?: ComponentProps<typeof Select>['onBlur'];
  onChange: (event: SelectChangeEvent<string | string[]>) => void;
  onFocus?: (event: ChangeEvent<HTMLSelectElement>) => void;
  categoryList?: { id: string; label: string }[];
  onInputBlur?: ComponentProps<typeof InputField>['onBlur'];
  onInputChange?: ComponentProps<typeof InputField>['onChange'];
  inputValue?: string;
  withSearch?: boolean;
}
const Dropdown: FunctionComponent<DropdownProps> = ({
  name,
  label,
  data,
  onBlur,
  onChange,
  value,
  multiSelect = false,
  disabled = false,
  rounded = false,
  className,
  type = 'default',
  size = 'normal',
  sx,
  categoryList,
  onInputBlur,
  onInputChange,
  inputValue,
  withSearch = false
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const MenuProps: ComponentProps<typeof Select>['MenuProps'] = {
    PaperProps: {
      sx: {
        maxHeight: '450px',
        '& .MuiList-root': {
          padding: '0px',
          // boxShadow: '0px 2px 16px rgba(80, 117, 177, 0.1)',
          borderRadius: '8px'
        },
        '& .MuiListSubheader-root': {
          padding: '10px 14px',
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: '"PT Sans", sans-serif',
          color: '#314157',
          backgroundColor: '#F1F5F9'
        },
        '& .Mui-selected': {
          backgroundColor: '#EFF9FB !important',
          color: '#096F84'
        },
        '& .MuiMenuItem-root': {
          padding: '10px 14px',
          backgroundColor: '#fff',
          '& .MuiTypography-root': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            fontFamily: '"PT Sans", sans-serif'
          },
          '&:after': {
            content: "''",
            position: 'absolute',
            right: '14px',
            bottom: '0',
            left: '14px',
            height: '1px',
            backgroundColor: 'rgba(142, 202, 230, 0.2)'
          }
        },
        '& .MuiCheckbox-root': {
          padding: '0 11px 0 0'
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: '#F1F5F9'
        }
      }
    },
    variant: 'menu'
  };

  const hasValue = value.length > 0;

  const mappedData = data?.map((item) => {
    return {
      id: item.id?.toString(),
      name: item.name
    };
  });

  const filteredData = data?.filter((item) => {
    return item?.name
      ?.toLowerCase()
      .includes(inputValue?.toLowerCase() ?? searchInput?.toLowerCase());
  });

  const renderTag = (v: string) => {
    const found = mappedData.find((a) => a.id === v);
    if (found && Array.isArray(value)) {
      return (
        <Tag
          key={found.id}
          onDelete={() =>
            onChange({
              target: { value: value.filter((k) => v !== k), name }
            } as unknown as SelectChangeEvent<string>)
          }
          label={found.name}
        />
      );
    }
  };

  const renderMenuitem = (item: DropdownData) => (
    <MenuItem key={item.id} value={item.id?.toString()}>
      {multiSelect && (
        <Checkbox
          checked={value.indexOf(item.id?.toString()) > -1}
          sx={{
            [`&, &.${checkboxClasses.root}`]: {
              color: '#8DA0B9'
            },
            [`&, &.${checkboxClasses.checked}`]: {
              color: '#096F84'
            }
          }}
        />
      )}
      <ListItemText primary={item.name} />
      {hasValue && !multiSelect && value?.toString() === item.id?.toString() && (
        <SvgIcon
          onClick={() =>
            onChange({
              target: { value: '', name }
            } as unknown as SelectChangeEvent<string>)
          }
          sx={{
            width: '16px',
            height: '16px'
          }}
          component={CloseIcon}
          inheritViewBox
          className={clsx(style.iconClose)}
        />
      )}
    </MenuItem>
  );

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) {
      onInputChange(event);
      return;
    }

    setSearchInput(event.target.value);
  };

  return (
    <div
      className={clsx(
        style.inputContainer,
        (expanded || hasValue) && style.inputContainerExpanded,
        disabled && style.inputContainerDisabled
      )}
    >
      <FormControl fullWidth>
        <Select
          onOpen={() => setExpanded(true)}
          onClose={() => setExpanded(false)}
          IconComponent={() => (
            <SvgIcon
              component={ArrowDownIcon}
              inheritViewBox
              className={clsx(
                style.icon,
                expanded && style.iconExpanded,
                disabled && style.iconDisabled
              )}
            />
          )}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          disabled={disabled}
          multiple={multiSelect}
          sx={{
            position: 'relative',
            ...sx
          }}
          className={clsx(
            style.select,
            rounded && style.rounded,
            disabled && style.disabled,
            rounded && disabled && style.roundedDisabled,
            size === 'small' && style.small,
            size === 'normal' && style.normal,
            type === 'success' && style.success,
            type === 'warning' && style.warning,
            className
          )}
          displayEmpty
          renderValue={(selected) => {
            if (multiSelect && Array.isArray(selected)) {
              if (selected.length === 0) {
                return label;
              }
              const newArr = selected?.map((item: string) => {
                if (mappedData.find((a) => a.id === item)) {
                  const found = mappedData.find((a) => a.id === item);
                  return found?.name;
                }
              });

              return newArr.join(', ');
            }
            if (selected) {
              return mappedData?.find((a) => a.id === selected.toString())?.name;
            }
            return label;
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={MenuProps}
        >
          {withSearch && (
            <div
              className={style.withSearch}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <InputField
                placeholder="Search"
                onBlur={onInputBlur}
                onChange={handleSearchInputChange}
                value={inputValue ?? searchInput}
                tags={<SvgIcon component={SearchIcon} inheritViewBox className={style.icon} />}
              />
            </div>
          )}
          {multiSelect && value.length > 0 && (
            <div className={style.tags}>
              {Array.isArray(value) && value?.map((v) => renderTag(v))}
            </div>
          )}
          {!categoryList?.length && filteredData?.map((item) => renderMenuitem(item))}
          {categoryList &&
            categoryList.length > 0 &&
            categoryList?.flatMap((option) => [
              option.label ? (
                <ListSubheader key={option.id} disableSticky>
                  {option.label}
                </ListSubheader>
              ) : (
                <div />
              ),
              ...filteredData.filter((obj) => obj.category === option.id).map(renderMenuitem)
            ])}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
