import * as React from 'react';
import { Breadcrumbs as BreadcrumbsMaterial } from '@mui/material';
import { Link } from 'react-router-dom';
import style from './breadcrumbs.module.scss';

interface BreadcrumbItem {
  path: string;
  name: string;
}

interface Props {
  breadcrumbItems: BreadcrumbItem[];
  breadcrumbLastItem: string;
}
const Breadcrumbs: React.FunctionComponent<Props> = ({ breadcrumbItems, breadcrumbLastItem }) => {
  return (
    <BreadcrumbsMaterial aria-label="breadcrumb" className={style.breadcrumbs}>
      {breadcrumbItems.map((item) => (
        <Link key={item.path} to={item.path}>
          {item.name}
        </Link>
      ))}
      <div className={style.last}>{breadcrumbLastItem}</div>
    </BreadcrumbsMaterial>
  );
};

export default Breadcrumbs;
