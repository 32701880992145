import { createSlice } from '@reduxjs/toolkit';
import {
  getRecruiterProfile,
  putRecruiterProfile,
  getUnSeenAwardsCount,
  getRecruiterProfileAwards,
  putUnSeenAwardsCounterReset
} from './profileActions';
import { notifyUserError } from '../../utils/notifications';
import { notifyUserSuccess } from '../../utils/notifications/index';

export interface ContentItem {
  id: number;
  dateTime: string;
  amount: string;
}

export interface ProfileSliceProps {
  profile: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    code: string;
    street: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    dob: string;
  } | null;
  awardsHistory: {
    content: ContentItem[] | [];
    page: number;
    size: number;
    totalElements: number;
  };
  notificationsCounter: number;
  awardsHistoryLoading: boolean;
}

const initialState: ProfileSliceProps = {
  profile: null,
  notificationsCounter: 0,
  awardsHistory: {
    content: [],
    page: 0,
    size: 6,
    totalElements: 0
  },
  awardsHistoryLoading: false
};

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecruiterProfile.fulfilled, (state: any, { payload }: any) => {
        state.profile = payload;
      })
      .addCase(getRecruiterProfile.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getUnSeenAwardsCount.fulfilled, (state: any, { payload }: any) => {
        state.notificationsCounter = payload.count;
      })
      .addCase(getUnSeenAwardsCount.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putUnSeenAwardsCounterReset.fulfilled, (state: any) => {
        state.notificationsCounter = 0;
      })
      .addCase(putUnSeenAwardsCounterReset.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putRecruiterProfile.fulfilled, (state: any, { payload }: any) => {
        state.profile = payload;
        notifyUserSuccess('Profile Updated');
      })
      .addCase(putRecruiterProfile.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getRecruiterProfileAwards.pending, (state: any, { payload }: any) => {
        state.awardsHistoryLoading = true;
      })
      .addCase(getRecruiterProfileAwards.fulfilled, (state: any, { payload }: any) => {
        state.awardsHistory = payload;
        state.awardsHistoryLoading = false;
      })
      .addCase(getRecruiterProfileAwards.rejected, (state: any, { payload }: any) => {
        state.awardsHistoryLoading = false;
        notifyUserError(payload);
      });
  }
});

export default profileSlice.reducer;
