import { Checkbox } from '@mui/material';
import { InputField, Popper } from 'components';
import { useFormik } from 'formik';
import { FC } from 'react';
import { putRecruiterData } from 'store/candidate/candidateActions';
import { RecruiterItem } from 'store/candidate/candidateSlice';
import { useAppDispatch } from 'store/hooks';
import { MSG_FIELD_LESS_13_CHARS, MSG_NUMBERS_ONLY } from 'utils/errorMessages';
import * as Yup from 'yup';
import { NUMBERS_REGEX } from '../../../constants';
import style from './styles.module.scss';

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  item?: RecruiterItem | null;
  open: boolean;
  getTableData: () => void;
  authCheckRolePayment: boolean;
}

const RecruiterPopper: FC<Props> = ({
  anchorEl,
  open,
  onClose,
  item,
  getTableData,
  authCheckRolePayment
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      ...item,
      proxyId: item?.proxyId || '',
      w9TaxFormSubmitted: item?.w9TaxFormSubmitted || false
    },

    validationSchema: Yup.object({
      proxyId: Yup.string()
        .max(13, MSG_FIELD_LESS_13_CHARS)
        .matches(NUMBERS_REGEX, MSG_NUMBERS_ONLY)
    }),
    onSubmit: (values) => {
      dispatch(putRecruiterData({ ...item, ...values }));
      getTableData();
      onClose();
    }
  });

  const renderComa = (data?: string) => {
    if (!data) return null;
    return `${data}, `;
  };

  if (!item) return null;

  return (
    <Popper
      anchorEl={anchorEl}
      placement="bottom-start"
      open={open}
      onClose={onClose}
      customClassName={style.recruiter}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <p>
            {item.firstName} {item.lastName}
          </p>
          <p>
            {renderComa(item.state)}
            {renderComa(item.zip)}
            {renderComa(item.city)}
            {item.street}
          </p>
          <p>{item.addressLine2}</p>
          <p>{item.email}</p>
          <p>{item.dob && `DOB: ${item.dob}`}</p>
          <p>{item.phone}</p>
        </div>
        <div className={style.proxyCheckbox}>
          <Checkbox
            defaultChecked={formik.values.w9TaxFormSubmitted}
            id="w9TaxFormSubmitted"
            name="w9TaxFormSubmitted"
            size="small"
            disabled={false}
            onChange={(e) => {
              formik.handleChange(e);
              if (e.currentTarget.value === 'true') {
                formik.setFieldValue('proxyId', '');
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.w9TaxFormSubmitted}
            sx={{
              color: '#219ebc',
              '&.Mui-checked': {
                color: '#219ebc'
              }
            }}
          />
          W9 Tax form submitted
        </div>
        <div className={style.proxyInput}>
          Proxy ID:
          <InputField
            name="proxyId"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.proxyId}
            disabled={!formik.values.w9TaxFormSubmitted}
          />
        </div>
        {formik.errors.proxyId ? (
          <div className={style.fieldError}>{formik.errors.proxyId}</div>
        ) : null}
        <div className={style.buttonsWrap}>
          <button
            className={style.cancel}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            className={style.submit}
            type="submit"
            disabled={!formik.dirty || !formik.isValid || !formik.values.proxyId}
          >
            Submit
          </button>
        </div>
      </form>
    </Popper>
  );
};

export default RecruiterPopper;
