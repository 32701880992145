import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Logo } from '../../components';
import { ProfilePopper } from '../../modules';
import { Avatar, Badge, SvgIcon } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrorDown_icon.svg';
import style from './header.module.scss';
import clsx from 'clsx';
import {
  getRecruiterProfile,
  getUnSeenAwardsCount,
  putUnSeenAwardsCounterReset
} from '../../store/profile/profileActions';
import { useUserAuthStatusChecker } from '../../utils/jwtDecoder';
import { ROLE } from '../../utils/protectedRoutes/userRolesTypes';
import { Logout } from '@mui/icons-material';
import { putLogoutUser } from '../../store/auth/authActions';
import { getLsRefreshToken } from '../networkTools/localStorageTokens';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface Props {}

const Header: FC<Props> = (props) => {
  const auth: any = useUserAuthStatusChecker();
  const authCheckRoleRecruiter = useMemo(
    () => auth?.roles.find((role: any) => role === ROLE.RECRUITER),
    [auth?.roles]
  );
  const anchorRef = useRef(null);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const profileState = useAppSelector((state) => state.profile);

  const profile = profileState.profile;
  const notificationsCounter = profileState.notificationsCounter;

  useEffect(() => {
    if (authCheckRoleRecruiter) {
      dispatch(getRecruiterProfile());
      dispatch(getUnSeenAwardsCount());
    }
  }, []);

  useEffect(() => {
    if (authCheckRoleRecruiter) {
      const interval = setInterval(async () => {
        await dispatch(getUnSeenAwardsCount());
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [notificationsCounter]);

  const handleClick = () => {
    if (notificationsCounter) {
      dispatch(putUnSeenAwardsCounterReset());
    }
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const notificationsCounterRender = (children: JSX.Element) => {
    if (notificationsCounter)
      return (
        <Badge badgeContent={notificationsCounter} className={style.badge}>
          {children}
        </Badge>
      );
    else return <>{children}</>;
  };

  const onHandleLogout = () => {
    const lsRefreshToken = getLsRefreshToken();
    if (lsRefreshToken) {
      dispatch(putLogoutUser(lsRefreshToken));
    }
  };

  return (
    <div className={style.header}>
      <div className={style.wrap}>
        <Logo type="small" />
      </div>
      {!authCheckRoleRecruiter && (
        <div className={style.logout} onClick={onHandleLogout}>
          <Logout className={style.logoutIcon} />
          Log out
        </div>
      )}
      {profile && (
        <>
          <div ref={anchorRef} onClick={handleClick} className={clsx(style.info, style.wrap)}>
            <div className={style.title}>
              <div>
                <div className={style.name}>
                  {profile.firstName} {profile.lastName}
                </div>
                <div className={style.status}>recruiter</div>
              </div>
              {notificationsCounterRender(
                <Avatar className={style.avatar}>{profile.firstName[0]}</Avatar>
              )}
            </div>
            <SvgIcon
              component={ArrowDownIcon}
              inheritViewBox
              className={clsx(style.icon, open && style.activeIcon)}
            />
          </div>
          <ProfilePopper open={open} anchorEl={anchorRef.current} onClose={onClose} />
        </>
      )}
    </div>
  );
};

export default Header;
