import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './utils/protectedRoutes';
import { ROLE } from './utils/protectedRoutes/userRolesTypes';
import './styles/global.scss';

import 'react-toastify/dist/ReactToastify.css';
import { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

const Page404 = lazy(() => import('./components').then((module) => ({ default: module.Page404 })));
const HomeProxyPage = lazy(() =>
  import('./pages').then((module) => ({ default: module.HomeProxyPage }))
);
const ThankYouPage = lazy(() => import('./pages/communityHealthWorker/thankYouPage/thankYouPage'));
const SignUpPage = lazy(() => import('./pages').then((module) => ({ default: module.SignUpPage })));
const LoginPage = lazy(() => import('./pages').then((module) => ({ default: module.LoginPage })));
const ResetPasswordPage = lazy(() => import('./pages/resetPassword/resetPasswordPage'));
const DashboardPage = lazy(() =>
  import('./pages').then((module) => ({ default: module.DashboardPage }))
);
const HiringPage = lazy(() => import('./pages').then((module) => ({ default: module.HiringPage })));
const CandidatePage = lazy(() =>
  import('./pages').then((module) => ({ default: module.CandidatePage }))
);
const CommunityHealthWorkerPage = lazy(
  () => import('./pages/communityHealthWorker/communityHealthWorker')
);

function App() {
  return (
    <div>
      <ToastContainer />
      <Suspense fallback={null}>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="*" element={<Page404 />} />
          <Route path="/" element={<HomeProxyPage />} />
          <Route path="thankYou" element={<ThankYouPage />} />
          <Route
            path="community-health-worker/:referenceLink"
            element={<CommunityHealthWorkerPage />}
          />
          {/* AUTH PUBLIC */}
          <Route path="auth">
            <Route path="signUp" element={<SignUpPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="resetPassword" element={<ResetPasswordPage />} />
          </Route>
          {/* PROTECTED ROUTES */}
          <Route element={<PrivateRoutes allowedRoles={[ROLE.RECRUITER]} />}>
            <Route path="dashboard" element={<DashboardPage />} />
          </Route>
          <Route element={<PrivateRoutes allowedRoles={[ROLE.COACH, ROLE.PAYMENT]} />}>
            <Route path="hiring" element={<HiringPage />} />
          </Route>
          <Route element={<PrivateRoutes allowedRoles={[ROLE.COACH]} />}>
            <Route path="hiring/candidate/:candidateId" element={<CandidatePage />} />
            <Route path="community-health-worker" element={<CommunityHealthWorkerPage />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
