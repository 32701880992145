import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SvgIcon
} from '@mui/material';
import clsx from 'clsx';
import { Title, LoadingSpinner } from '../../../components';
import { getRecruiterProfileAwards } from '../../../store/profile/profileActions';
import { ContentItem } from '../../../store/profile/profileSlice';
import { ReactComponent as ArrowDownIcon } from '../../../assets/svg/arrorDown_icon.svg';
import style from './profile.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const SortValuesTitles = ['amount', 'dateTime'];

enum SortValues {
  ASC = 'asc',
  DESC = 'desc'
}

interface HeadCell {
  id: string;
  label: string;
  sort?: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'amount',
    label: 'Amount',
    sort: true
  },
  {
    id: 'dateTime',
    label: 'Date',
    sort: true
  }
];

interface Props {}

const AwardsHistory: FC<Props> = () => {
  const listInnerRef = useRef(null);
  const dispatch = useAppDispatch();
  const [dataContent, setDataContent] = useState<ContentItem[]>([]);
  const [sortConfig, setSortConfig] = useState(
    SortValuesTitles.map<any>((item) => ({
      title: item,
      direction: null
    }))
  );
  const { awardsHistory, awardsHistoryLoading } = useAppSelector((state) => state.profile);
  const { totalElements, content, size, page } = awardsHistory;

  useEffect(() => {
    if (content.length > 0) {
      let arr = [...dataContent, ...content];
      if (page === 0) arr = content;
      const arrSorted = arr.filter(
        (tag, index, array) => array.findIndex((t) => t.id == tag.id) == index
      );
      setDataContent(arrSorted);
    }
  }, [content, page, totalElements]);

  useEffect(() => {
    dispatch(
      getRecruiterProfileAwards({
        page: 0,
        size: size
      })
    );
  }, []);

  const sort = (itemSort: any) => {
    if (!itemSort) return false;

    const data = sortConfig.map((item) => {
      if (item.title === itemSort) {
        let directionData: string | null = SortValues.ASC;
        if (item.direction === SortValues.ASC) {
          directionData = SortValues.DESC;
        }
        if (item.direction === SortValues.DESC) {
          directionData = null;
        }
        return {
          ...item,
          direction: directionData
        };
      }
      return item;
    });
    setSortConfig(data);
    dispatch(
      getRecruiterProfileAwards({
        page: 0,
        size: size,
        sort: data
      })
    );
  };

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight === scrollHeight &&
        !awardsHistoryLoading &&
        dataContent.length < totalElements
      ) {
        dispatch(
          getRecruiterProfileAwards({
            page: page + 1,
            size: size,
            sort: sortConfig
          })
        );
      }
    }
  }, [dataContent.length, awardsHistoryLoading]);

  const checkNumber = (num: string) => {
    if (Number(num.split('.').pop()) === 0) {
      return num.split('.')[0];
    }
    return num;
  };

  if (!awardsHistory) return null;

  return (
    <div className={style.awardsHistory}>
      <Title type="h1" text="Awards history" />
      <TableContainer className={style.tableContainer} ref={listInnerRef} onScroll={onScroll}>
        <Table stickyHeader aria-label="Awards history">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  className={item.sort ? style.pointer : ''}
                  onClick={() => item.sort && sort(item.id)}
                  key={item.id}
                >
                  {item.label}
                  <span className={style.iconWrapper}>
                    {sortConfig.find((sortItem) => sortItem.title === item.id).direction !==
                      SortValues.DESC && (
                      <SvgIcon
                        component={ArrowDownIcon}
                        inheritViewBox
                        className={clsx(style.icon)}
                      />
                    )}
                    {sortConfig.find((sortItem) => sortItem.title === item.id).direction !==
                      SortValues.ASC && (
                      <SvgIcon
                        component={ArrowDownIcon}
                        inheritViewBox
                        className={clsx(style.icon, style.activeIcon)}
                      />
                    )}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataContent.length !== 0 &&
              dataContent.map((item: ContentItem) => (
                <TableRow key={item.id}>
                  <TableCell>$ {checkNumber(item.amount)}</TableCell>
                  <TableCell>{item.dateTime}</TableCell>
                </TableRow>
              ))}
            {dataContent.length === 0 && !awardsHistoryLoading && (
              <TableRow>
                <TableCell>no history</TableCell>
                <TableCell />
              </TableRow>
            )}
            {awardsHistoryLoading && (
              <TableRow className={style.spinner}>
                <TableCell>
                  <LoadingSpinner />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AwardsHistory;
