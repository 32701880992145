export const getLsAccessToken = () => {
  return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;
};
export const getLsRefreshToken = () => {
  return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null;
};
export const updateLsAccessToken = (newToken: string) => {
  localStorage.removeItem('accessToken');
  localStorage.setItem('accessToken', newToken);
};

export const deleteLsBothTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};
export const setLsBothTokens = (access: string, refresh: string) => {
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
};
